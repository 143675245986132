import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect, useSelector } from 'react-redux';

// import Moment from 'react-moment';

import ReactGA from 'react-ga';

import { withTranslation } from 'react-i18next';


import { Link, withRouter } from 'react-router-dom';

// import Row from 'reactstrap/lib/Row';
// import Col from 'reactstrap/lib/Col';
// import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
/* import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';  */

import CustomModal from '../../../Components/Components/Modal';

// import ScrollArea from 'react-scrollbar';
// import ScoreTable from '../../../../../../containers/Game/ScoreTable';

import ScoreTable from './ScoreTable';
import ScoreTableSum from './ScoreTableSum';
import PartyLog from './PartyLog';
import LeaveRoomPenalty from '../../../Menu/RulesPage/Tabs/LeaveRoomPenalty';

// import ScrollAreaButtons from '../../../../UI/ScrollAreaButtons';

// import closeImg from '../../../../../../images/icons/close.png';
// import leaderboardImg from '../../../../../../images/icons/leaderboard.svg';
import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';

import { getBetValue } from '../../../../../../common/services/data-service';

import {
  getPartyLog, setRoomEnded, setTournamentRoomEnded
} from '../../../../../../actions/room';
import missedInfoImg from '../../../../../../images/redesign/game/missedInfo.png';
import { SCREEN_MODE, sendLanguage } from '../../../../../../constants/constants';
import config from '../../../../../../constants/config';

class EndResultModal extends PureComponent {
  static propTypes = {
    setShowEndResultModal: PropTypes.func.isRequired,
    setRoomEndedFunction: PropTypes.func.isRequired,
    setTournamentRoomEndedFunction: PropTypes.func.isRequired,
    roomId: PropTypes.string,
    leaveRoom: PropTypes.func.isRequired,
    playButtonSound: PropTypes.func.isRequired,
    gameT: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    fetchPartyLog: PropTypes.func.isRequired,
    partyLog: PropTypes.arrayOf(PropTypes.shape()),
    closeReason: PropTypes.shape(),
    players: PropTypes.shape(),
    bet: PropTypes.string,
    minGames: PropTypes.number,
    rPlayed: PropTypes.number,
    fourthPlayerMissed: PropTypes.bool,
    tournamentRoom: PropTypes.bool,
    modalOpen: PropTypes.bool,
    notificationSettings: PropTypes.shape(),
    member: PropTypes.shape({
      uid: PropTypes.string,
    }),
    screenMode: PropTypes.string,
    player1ShortName: PropTypes.string,
    player2ShortName: PropTypes.string,
    player3ShortName: PropTypes.string,
    player4ShortName: PropTypes.string,

    player1Uid: PropTypes.string,
    player2Uid: PropTypes.string,
    player3Uid: PropTypes.string,
    player4Uid: PropTypes.string,

    fourPRoom: PropTypes.bool,
    tournamentRoom: PropTypes.bool,
    screenMode: PropTypes.string,
  };

  static defaultProps = {
    screenMode: SCREEN_MODE.normal,
    roomId: null,
    closeReason: {},
    players: {},
    member: {},
    notificationSettings: {},
    partyLog: [],
    bet: null,
    rPlayed: null,
    minGames: null,
    fourthPlayerMissed: false,
    tournamentRoom: false,
    modalOpen: false,
    player1ShortName: null,
    player2ShortName: null,
    player3ShortName: null,
    player4ShortName: null,

    player1Uid: null,
    player2Uid: null,
    player3Uid: null,
    player4Uid: null,
    fourPRoom: false,
    screenMode: SCREEN_MODE.normal,
  };

  constructor(props) {
    super(props);
    this.state = {
      //  error: null,
      //  roomId: '',
      openMissedInfoModal: false,
    };
  }


  componentDidMount() {
    const { leaveRoom, notificationSettings } = this.props;

    const {
      closeReason, bet, minGames, rPlayed,
    } = this.props;

    //  let betValue;
    let leavePenalty;
    let leaveReward;


    // if (closeReason && closeReason.isAutomated) {
    //   leavePenalty = 0;
    //   leaveReward = 0;
    // } else {
    //   leavePenalty = Math.round(betValue * 16);
    //   leaveReward = Math.round(0.25 * leavePenalty);
    // }

    // if (closeReason && closeReason.reason === 'leftRoom') {
    //   if (minGames && (rPlayed || rPlayed === 0) && minGames > (rPlayed) && betValue) {
    //     leavePenalty += Math.round((minGames - rPlayed) * 10 * betValue);

    //     leaveReward += Math.round(0.25 * (minGames - rPlayed) * 10 * betValue);
    //   }
    // } else if (minGames && (rPlayed || rPlayed === 0) && minGames > (rPlayed + 1) && betValue) {
    //   leavePenalty += Math.round((minGames - rPlayed) * 10 * betValue);

    //   leaveReward += Math.round(0.25 * (minGames - rPlayed) * 10 * betValue);
    // }

    /*  if (minGames && (rPlayed || rPlayed === 0) && minGames > (rPlayed + 1) && betValue) {
      leavePenalty += Math.round((minGames - rPlayed) * 10 * betValue);

      leaveReward += Math.round(0.25 * (minGames - rPlayed) * 10 * betValue);
    } */

    // this.setState({ leaveReward, leavePenalty });
  }

  componentWillUnmount() {
    //  clearTimeout(this.timeoutID);
  }

  // componentDidUpdate(nextProps) {
  //   const {
  //     closeReason, bet, minGames, rPlayed,
  //   } = this.props;

  //   //  let betValue;
  //   let leavePenalty;
  //   let leaveReward;
  //   if (closeReason !== nextProps.closeReason ) {

  //   }
  // }

  fetchPartyLog = (party, pulesTitle) => {
    const { roomId, fetchPartyLog } = this.props;
    fetchPartyLog(roomId, party).then(() => {
      ReactGA.event({
        category: 'Game',
        action: 'Open Round Info',
      });

      this.setState({
        showPartyModal: true,
        pulesPartyModal: pulesTitle ? true: false,
      }); // , activeParty: party
    });
  }

  closePartyModal = () => {
    this.setState({
      showPartyModal: false,
      pulesPartyModal: false,
    }); // , activeParty: null
  }

  // updateScrollPos = (val) => {
  //  this.setState({ scrollPos: val.topPosition || 0 });
  // }

  scroll = (newVal) => {
    this.pointsScrollbar.scrollYTo(newVal);
  }

  leaveRoom = () => {
    const {
      closeReason, leaveRoom, playButtonSound, history, setRoomEndedFunction, setTournamentRoomEndedFunction, tournamentRoom,
    } = this.props;
    playButtonSound();
    leaveRoom();

    if (closeReason) {
      console.log('history.push to leaveRoom 2222', { history: history.location });
      setRoomEndedFunction(true);
      if (tournamentRoom) {
        setTournamentRoomEndedFunction(true);
      }
      history.push('/');

      console.log('history.push to leaveRoom 2 2222', { history: history.location });
    }
  }

  setShowEndResultModal = (value) => {
    const { setShowEndResultModal } = this.props;

    console.log('setShowEndResultModal test');

    setShowEndResultModal(value);
    this.leaveRoom();
  }

  showInfoModal = () => {
    this.setState(prevState => ({
      openMissedInfoModal: !prevState.openMissedInfoModal,
    }));
  }

  render = () => {
    const {
      players,
      roomId,
      leaveRoom,
      gameT,
      playButtonSound,
      //  closeReason,
      t,
      i18n,
      fourthPlayerMissed,
      member,
      partyLog,
      tournamentRoom,
      tournamentRound,
      modalOpen,

      points,
      totalPnts,

      myPos,
      bet,
      party,
      gameType,
      closeReason,
      notificationSettings,
      minGames,
      rPlayed,

      player1ShortName,
      player2ShortName,
      player3ShortName,
      player4ShortName,

      player1Uid,
      player2Uid,
      player3Uid,
      player4Uid,
      fourPRoom,
      screenMode,
      setShowEndResultModal,
    } = this.props;

    const {
      showPartyModal,
      openMissedInfoModal,
      pulesPartyModal,
    } = this.state;

    if (!modalOpen) {
      return null;
    }
    if (!closeReason) {
      return null;
    } else if (notificationSettings && !notificationSettings.showAtRoomEnd) {
      leaveRoom();
      return null;
    }
    let leavePenalty = 0;
    let leaveReward = 0;

    const betValue = getBetValue(bet);
    this.setState({ betValue });

    if (closeReason && closeReason.isAutomated) {
      leavePenalty = 0;
      leaveReward = 0;
    } else {
      leavePenalty = fourPRoom ? Math.round(betValue * 24) : Math.round(betValue * 16);
      leaveReward = Math.round(0.25 * leavePenalty);
    }

    if (closeReason.reason === 'leftRoom') {
      if (minGames && (rPlayed || rPlayed === 0) && minGames > (rPlayed) && betValue) {
        leavePenalty += Math.round((minGames - rPlayed) * 10 * betValue);

        leaveReward += Math.round(0.25 * (minGames - rPlayed) * 10 * betValue);
      }
    } else if (minGames && (rPlayed || rPlayed === 0) && minGames > rPlayed && betValue) {
      leavePenalty += Math.round((minGames - rPlayed) * 10 * betValue);

      leaveReward += Math.round(0.25 * (minGames - rPlayed) * 10 * betValue);
    }
    console.log(leavePenalty, leaveReward, closeReason.reason, minGames, rPlayed, betValue, "closeReason****");

    const isInAppFrame = config.isInAppFrame();
    return (
      <div>

        <CustomModal
          isOpen={modalOpen}
          toggle={() => this.setShowEndResultModal(false)}
          size="lg"
          title={gameT('results')}
          footer={(
            <>
              <Button className="notification-footer-button" onClick={() => this.setShowEndResultModal(false)}>
                {gameT('ok')}
              </Button>
            </>
          )}
          addClassName="game-result-modal"
          body={(
            <>
              <div className="game-result-section">
                <ScoreTable
                  roomId={roomId}
                  scoreTableOpen
                  isEndResultTable
                  fetchPartyLog={this.fetchPartyLog}
                  closeReason={closeReason}


                  totalPnts={totalPnts}
                  points={points}

                  myPos={myPos}
                  bet={bet}
                  party={party}
                  gameType={gameType}

                  player1ShortName={player1ShortName}
                  player2ShortName={player2ShortName}
                  player3ShortName={player3ShortName}
                  player4ShortName={player4ShortName}

                  player1Uid={player1Uid}
                  player2Uid={player2Uid}
                  player3Uid={player3Uid}
                  player4Uid={player4Uid}

                  screenMode={screenMode}
                  t={t}
                />
                {
                  <ScoreTableSum showInfoModal={this.showInfoModal} totalPnts={totalPnts} myPos={myPos} isEndResultTable gameType={gameType} bet={bet} closeReason={closeReason} t={t} />
                }
                {closeReason && (
                  <Fragment>
                    <div className="end-result-table-close-info">
                      <div className='end-result-table-close-info-image mr-3 mt-1'>
                        <Media src={missedInfoImg} />
                      </div>
                      {(!tournamentRoom && closeReason && closeReason.reason === 'lastRound') ? (
                        <div className="end-result-modal-close-reason">
                          {t('lastRound', { player: closeReason.playerName })}
                        </div>
                      ) : (null)}
                      {(tournamentRoom && closeReason && closeReason.reason === 'lastRound' && closeReason.playerUid === 'tournament') ? (
                        <>
                          {tournamentRound === 'last' ? (
                            <div className="end-result-modal-close-reason">
                              {t('lastRoundTournamentLast')}
                            </div>
                          ) : (
                            <div className="end-result-modal-close-reason">
                              {t('lastRoundTournament', { round: tournamentRound || 1 })}
                            </div>
                          )}
                        </>
                      ) : (null)}
                      {(closeReason && closeReason.reason === 'missedTurn') ? (
                        <>
                          {closeReason.isAutomated ? (
                            <>
                              {closeReason.playerUid === member.uid ? (
                                <div className="end-result-modal-close-reason">
                                  <>
                                    {leavePenalty !== 0 ? (
                                      <>
                                        {t('missedTurnMessageAutomatedMe', { player: closeReason.playerName, amount: leavePenalty })}
                                      </>
                                    ) : (
                                      <>
                                        {t('missedTurnMessageAutomatedMeZero', { player: closeReason.playerName })}
                                      </>
                                    )}
                                  </>
                                </div>
                              ) : (
                                <div className="end-result-modal-close-reason">
                                  <>
                                    {leaveReward !== 0 ? (
                                      <>
                                        {t('missedTurnMessageAutomatedOther', { player: closeReason.playerName, amount: leaveReward })}
                                      </>
                                    ) : (
                                      <>
                                        {t('missedTurnMessageAutomatedOtherZero', { player: closeReason.playerName })}
                                      </>
                                    )}
                                  </>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {closeReason.playerUid === member.uid ? (
                                <div className="end-result-modal-close-reason">
                                  {t('missedTurnMessageMe', { player: closeReason.playerName, amount: leavePenalty })}
                                </div>
                              ) : (
                                <div className="end-result-modal-close-reason">
                                  {t('missedTurnMessageOther', { player: closeReason.playerName, amount: leaveReward })}
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ) : (null)}
                      {(closeReason && closeReason.reason === 'leftRoom') ? (
                        <>
                          {closeReason.playerUid === member.uid ? (
                            <div className="end-result-modal-close-reason">
                              {t('leftRoomMe', { player: closeReason.playerName, amount: leavePenalty })}
                              <>
                                {' '}
                                {tournamentRound === 'last' ? (t('lastRoundTournamentLast')) : (t('lastRoundTournament', { round: tournamentRound || 1 }))}
                              </>
                            </div>
                          ) : (
                            <div className="end-result-modal-close-reason">
                                {t('leftRoomOther', { player: closeReason.playerName, amount: leaveReward })}
                                <>
                                  {' '}
                                  {tournamentRound === 'last' ? (t('lastRoundTournamentLast')) : (t('lastRoundTournament', { round: tournamentRound || 1 }))}
                                </>
                            </div>
                          )}
                        </>
                      ) : (null)}
                    </div>
                  </Fragment>
                )}
                {fourthPlayerMissed ? (
                  <div className="end-result-modal-close-reason">
                    {t('missedTurn', { player: member.name })}
                  </div>
                ) : (null)}
              </div>
              <div className="game-result-mobile-landscape d-none">
                <ScoreTable
                  roomId={roomId}
                  scoreTableOpen
                  isEndResultTable
                  fetchPartyLog={this.fetchPartyLog}
                  closeReason={closeReason}


                  totalPnts={totalPnts}
                  points={points}

                  myPos={myPos}
                  bet={bet}
                  party={party}
                  gameType={gameType}

                  player1ShortName={player1ShortName}
                  player2ShortName={player2ShortName}
                  player3ShortName={player3ShortName}
                  player4ShortName={player4ShortName}

                  player1Uid={player1Uid}
                  player2Uid={player2Uid}
                  player3Uid={player3Uid}
                  player4Uid={player4Uid}

                  screenMode={screenMode}
                  t={t}
                />
                <ScoreTableSum
                  showInfoModal={this.showInfoModal}
                  totalPnts={totalPnts}
                  myPos={myPos}
                  player1ShortName={player1ShortName}
                  player2ShortName={player2ShortName}
                  player3ShortName={player3ShortName}
                  player4ShortName={player4ShortName}
                  isEndResultTable
                  gameType={gameType}
                  bet={bet}
                  closeReason={closeReason}
                  t={t}
                />

                {closeReason && (
                  <Fragment>
                    <div className="end-result-table-close-info">
                      <div className='end-result-table-close-info-image mr-3 mt-1'>
                        <Media src={missedInfoImg} />
                      </div>
                      {(!tournamentRoom && closeReason && closeReason.reason === 'lastRound') ? (
                        <div className="end-result-modal-close-reason">
                          {t('lastRound', { player: closeReason.playerName })}
                        </div>
                      ) : (null)}
                      {(tournamentRoom && closeReason && closeReason.reason === 'lastRound' && closeReason.playerUid === 'tournament') ? (
                        <>
                          {tournamentRound === 'last' ? (
                            <div className="end-result-modal-close-reason">
                              {t('lastRoundTournamentLast')}
                            </div>
                          ) : (
                            <div className="end-result-modal-close-reason">
                              {t('lastRoundTournament', { round: tournamentRound || 1 })}
                            </div>
                          )}
                        </>
                      ) : (null)}
                      {(closeReason && closeReason.reason === 'missedTurn') ? (
                        <>
                          {closeReason.isAutomated ? (
                            <>
                              {closeReason.playerUid === member.uid ? (
                                <div className="end-result-modal-close-reason">
                                  <>
                                    {leavePenalty !== 0 ? (
                                      <>
                                        {t('missedTurnMessageAutomatedMe', { player: closeReason.playerName, amount: leavePenalty })}
                                      </>
                                    ) : (
                                      <>
                                        {t('missedTurnMessageAutomatedMeZero', { player: closeReason.playerName })}
                                      </>
                                    )}
                                  </>
                                </div>
                              ) : (
                                <div className="end-result-modal-close-reason">
                                  <>
                                    {leaveReward !== 0 ? (
                                      <>
                                        {t('missedTurnMessageAutomatedOther', { player: closeReason.playerName, amount: leaveReward })}
                                      </>
                                    ) : (
                                      <>
                                        {t('missedTurnMessageAutomatedOtherZero', { player: closeReason.playerName })}
                                      </>
                                    )}
                                  </>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {closeReason.playerUid === member.uid ? (
                                <div className="end-result-modal-close-reason">
                                  {t('missedTurnMessageMe', { player: closeReason.playerName, amount: leavePenalty })}
                                </div>
                              ) : (
                                <div className="end-result-modal-close-reason">
                                  {t('missedTurnMessageOther', { player: closeReason.playerName, amount: leaveReward })}
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ) : (null)}
                      {(closeReason && closeReason.reason === 'leftRoom') ? (
                        <>
                          {closeReason.playerUid === member.uid ? (
                            <div className="end-result-modal-close-reason">
                              {t('leftRoomMe', { player: closeReason.playerName, amount: leavePenalty })}
                              <>
                                {' '}
                                {tournamentRound === 'last' ? (t('lastRoundTournamentLast')) : (t('lastRoundTournament', { round: tournamentRound || 1 }))}
                              </>
                            </div>
                          ) : (
                            <div className="end-result-modal-close-reason">
                                {t('leftRoomOther', { player: closeReason.playerName, amount: leaveReward })}
                                <>
                                  {' '}
                                  {tournamentRound === 'last' ? (t('lastRoundTournamentLast')) : (t('lastRoundTournament', { round: tournamentRound || 1 }))}
                                </>
                            </div>
                          )}
                        </>
                      ) : (null)}
                    </div>
                  </Fragment>
                )}
                {fourthPlayerMissed ? (
                  <div className="end-result-modal-close-reason">
                    {t('missedTurn', { player: member.name })}
                  </div>
                ) : (null)}
              </div>
            </>
          )}
        />

        <CustomModal
          isOpen={showPartyModal}
          toggle={this.closePartyModal}
          width={players && players.player4 ? 965 : 765}
          title={pulesPartyModal ? t('game:calculationOfPulesModal') : t('game:gameDetails')}
          addClassName={"gameinfo-modal"}
          footer={(
            <>
              <Button className="notification-footer-button" onClick={this.closePartyModal}>
                {gameT('ok')}
              </Button>
            </>
          )}
          body={(
            <div className="end-result-party-log-modal">
              <PartyLog screenMode={screenMode} partyLog={partyLog} tournamentRound={tournamentRound} players={players} t={t} />
            </div>
          )}
        />

        <CustomModal
          isOpen={openMissedInfoModal}
          toggle={this.showInfoModal}
          size="md"
          title={gameT('penaltyInfo')}
          shiftCloseButton={true}
          footer={(
            <>
              <Button className="notification-footer-button" onClick={this.showInfoModal}>
                {gameT('ok')}
              </Button>
            </>
          )}
          body={(
            <div className="end-result-party-log-modal">
              <ScrollAreaWrapper
                className="end-result-table-scrollarea"
                contentClassName="end-result-table-body end-result-table-body-leave-room"
                show
                rightOffset={0}
                topOffset={23}
                bottomOffset={-3}
              >
                <LeaveRoomPenalty lang={i18n.language || sendLanguage.lv} />
              </ScrollAreaWrapper>
            </div>
          )}
        />
      </div>
    );
  }
}
// );

const mapStateToProps = state => ({
  players: state.game.players || {},
  partyLog: state.rooms.partyLog || [],

  totalPnts: state.points.totalPoints || {},
  points: state.points.points || {},

  myPos: state.game.myPos || 'player1',
  bet: state.game.globalParams.bet || null,
  party: state.game.globalParams.party || null,
  gameType: state.game.globalParams.gameType || null,
  closeReason: state.game.globalParams.closeReason || null,
  tournamentRoom: state.game.globalParams.tournamentRoom || null,

  player1ShortName: state.game.players.player1 ? state.game.players.player1.shortName : '',
  player2ShortName: state.game.players.player2 ? state.game.players.player2.shortName : '',
  player3ShortName: state.game.players.player3 ? state.game.players.player3.shortName : '',
  player4ShortName: state.game.players.player4 ? state.game.players.player4.shortName : '',

  player1Uid: state.game.players.player1 ? state.game.players.player1.uid : '',
  player2Uid: state.game.players.player2 ? state.game.players.player2.uid : '',
  player3Uid: state.game.players.player3 ? state.game.players.player3.uid : '',
  player4Uid: state.game.players.player4 ? state.game.players.player4.uid : '',

});

const mapDispatchToProps = {
  fetchPartyLog: getPartyLog,
  setRoomEndedFunction: setRoomEnded,
  setTournamentRoomEndedFunction: setTournamentRoomEnded,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['notifications', 'game'])(withRouter(EndResultModal)));
