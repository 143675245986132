import React from 'react';
import PropTypes from 'prop-types';

import Media from 'reactstrap/lib/Media';

import { withTranslation } from 'react-i18next';
import isEqual from 'react-fast-compare';
import { map, filter, size } from 'lodash';

import ScrollArea from 'react-scrollbar';
import ScrollAreaButtons from '../../UI/ScrollAreaButtons';

import coinImg from '../../../../images/coin.svg';

import ScoreTableRow from './ScoreTable4pRow';
import Player4PositionScore from './Player4PositionScore';
import EndResultPules from '../EndResultModal/EndResultPules4p';
import EndResultFines from '../EndResultModal/EndResultFines4p';
import * as constants from '../../../../constants/constants';
import { getBetValue } from '../../../../common/services/data-service';
import { connect } from 'react-redux';

class ScoreTable extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    player1ShortName: PropTypes.string,
    player2ShortName: PropTypes.string,
    player3ShortName: PropTypes.string,
    player4ShortName: PropTypes.string,
    player1Uid: PropTypes.string,
    player2Uid: PropTypes.string,
    player3Uid: PropTypes.string,
    player4Uid: PropTypes.string,
    points: PropTypes.shape(),
    totalPnts: PropTypes.shape({
      player1: PropTypes.number,
      player2: PropTypes.number,
      player3: PropTypes.number,
      player4: PropTypes.number,
    }),
    myPos: PropTypes.string,
    scoreTableOpen: PropTypes.bool,
    party: PropTypes.number,
    bet: PropTypes.string,
    fetchPartyLog: PropTypes.func,
    isEndResultTable: PropTypes.bool,
    closeReason: PropTypes.shape(),
    gameType: PropTypes.string,
    showInfoModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    player1ShortName: null,
    player2ShortName: null,
    player3ShortName: null,
    player4ShortName: null,
    player1Uid: null,
    player2Uid: null,
    player3Uid: null,
    player4Uid: null,
    points: {},
    totalPnts: {},
    myPos: null,
    scoreTableOpen: true,
    party: null,
    bet: null,
    fetchPartyLog: null,
    isEndResultTable: false,
    closeReason: null,
    gameType: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollPos: 0,
    };
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  shouldComponentUpdate(nextProps) {
    if (!isEqual(nextProps, this.props)) {
      this.scrollToBottom();
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  scrollToBottom = () => {
    if (this.pointsScrollbar) {
      this.timeoutID = setTimeout(() => {
        if (this.pointsScrollbar) {
          this.pointsScrollbar.scrollBottom();
        }
      }, 20);
    }
  };

  setLastRef = (el) => {
    this.lastRef = el;
  };

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.pointsScrollbar.scrollYTo(newVal);
  }

  fetchPartyLog = (party) => {
    const { fetchPartyLog, isEndResultTable } = this.props;

    if (fetchPartyLog && isEndResultTable) {
      fetchPartyLog(party);
    }
  }

  renderPoints = (key, index) => {
    const {
      points,
      player1ShortName,
      player2ShortName,
      player3ShortName,
      player4ShortName,
      myPos,
      party,
      //  fetchPartyLog,
      isEndResultTable,
    } = this.props;
    return (
      <tr
        key={key}
        className={`score-table-row ${(index + 1) === size(points) && points[key].id === '-' && isEndResultTable ? 'pules-delimiter-line' : ''}`}
        ref={(el) => {
          if (party === index + 2) {
            this.lastRef = el;
          }
        }}
      >
        <ScoreTableRow
          player1={points[key].player1}
          player2={points[key].player2}
          player3={points[key].player3}
          player4={points[key].player4}
          pule={points[key].pule}
          player1ShortName={player1ShortName || ''}
          player2ShortName={player2ShortName || ''}
          player3ShortName={player3ShortName || ''}
          player4ShortName={player4ShortName || ''}
          myPos={myPos}
          index={points[key].id || null}
          roundIndex={index}
          isEndResultTable={isEndResultTable}
          fetchPartyLog={this.fetchPartyLog}
          pulesTitle={(index + 1) === size(points) && points[key].id === '-' && isEndResultTable ? 'pules-delimiter-line' : ''}
        />
      </tr>
    );
  };

  renderMissedRound = () => {
    const {
      player1Uid,
      player2Uid,
      player3Uid,
      player4Uid,
      //  points,
      player1ShortName,
      player2ShortName,
      player3ShortName,
      player4ShortName,
      myPos,
      party,
      //  fetchPartyLog,
      isEndResultTable,
      closeReason,
      showInfoModal,
    } = this.props;

    if (closeReason && closeReason.playerUid) {
    //  const { playerUid } = closeReason;

      return (
        <tr
          key="missed-row"
          className="score-table-row"
          ref={(el) => { this.lastRef = el; }}
        >
          <ScoreTableRow
            player1={closeReason.playerUid === player1Uid ? -16 : 4}
            player2={closeReason.playerUid === player2Uid ? -16 : 4}
            player3={closeReason.playerUid === player3Uid ? -16 : 4}
            player4={closeReason.playerUid === player4Uid ? -16 : 4}
            pule={null}
            player1ShortName={player1ShortName || ''}
            player2ShortName={player2ShortName || ''}
            player3ShortName={player3ShortName || ''}
            player4ShortName={player4ShortName || ''}
            myPos={myPos}
            index="-"
            roundIndex={party - 1}
            isEndResultTable={isEndResultTable}
            fetchPartyLog={this.fetchPartyLog}
          />
        </tr>
      );
    }
    return null;
  };

  render() {
    const {
      t,
      player1ShortName,
      player2ShortName,
      player3ShortName,
      player4ShortName,
      totalPnts,
      points,
      myPos,
      scoreTableOpen,
      bet,
      isEndResultTable,
      closeReason,
      gameType,
      showInfoModal,
      players,
      globalParams,
    } = this.props;

    const { scrollPos } = this.state;

    let betRatio;

    if (bet) {
      betRatio = parseInt(bet.replace('1:', ''), 10);
    }
    const pPules = [];
    if (points) {
      map(points, (item, key) => {
        if (item.pule) {
          // console.log(item.pule, "result pule");
          const puleType = item.pule;
          if (puleType !== constants.COMMON_PULE.p || puleType !== constants.COMMON_PULE._p) {
            pPules.push(puleType);
          }
        }
      });
    }
  
    console.log("old four player score===")
    return (
      <div className={`score-table ${!scoreTableOpen ? 'display-none' : ''}`}>
        <table className="score-table-fourPlayer full-width">
          <colgroup>
            <col span="1" className="" />
          </colgroup>
          <thead className="full-width header-full">
            <tr>
              <th className="score-table-id-header width-10">#</th>
              <Player4PositionScore myPos={myPos} player1ShortName={player1ShortName} player2ShortName={player2ShortName} player3ShortName={player3ShortName} player4ShortName={player4ShortName} playerPules={pPules} />
              <th className="score-table-pules-header width-10">
                <div className="score-table-header-player">
                  {t('pules')}
                </div>
                <div className="plues-section">
                  {
                    // pPules && map(filter(pPules, (pule) => { return pule === 'P' || pule === 'P-'; }), item => (
                      pPules && map(filter(pPules, (pule) => { return pule === 'P'; }), item => (
                      <div className='under-pules' />
                    ))
                  }
                </div>
              </th>
              {isEndResultTable ? (
                <th className="score-table-info-header" />
              ) : (null)}
            </tr>
          </thead>
          <tbody />
        </table>
        <div className="score-table-scroll-area-wrapper">
          <ScrollAreaButtons
            scrollPos={scrollPos}
            show={this.pointsScrollbar && this.pointsScrollbar.state && this.pointsScrollbar.state.realHeight > this.pointsScrollbar.state.containerHeight ? true : null}
            scroll={this.scroll}
            speed={30}
            rightOffset={isEndResultTable ? 16 : -1}
            topOffset={isEndResultTable ? 57 : 0}
            bottomOffset={isEndResultTable ? 108 : -1}
            bgColor={isEndResultTable ? '#2c5955' : '#2d191a'}
          />
          <ScrollArea
            speed={0.55}
            className="score-table-scroll-area"
            contentClassName="score-table-scroll-content score-table-fourPlayer-scroll-content"
            verticalContainerStyle={{
              background: 'transparent',
              opacity: 1,
              width: 11,
              marginRight: 2,
            }}
            verticalScrollbarStyle={{
              background: '#fff',
              borderRadius: 1,
              width: 10,
            }}
            minScrollSize={25}
            horizontal={false}
            onScroll={this.updateScrollPos}
            ref={(el) => {
              this.pointsScrollbar = el;
            }}
          >
            <table style={{ width: '100%' }}>
              <thead />
              <tbody>
                {points && Object.keys(points).map(this.renderPoints)}
                {/* {(isEndResultTable && closeReason && (closeReason.reason === 'missedTurn' || closeReason.reason === 'leftRoom') && !closeReason.isAutomated) ? (
                  <>
                    {this.renderMissedRound()}
                  </>
                ) : (null)} */}
              </tbody>
            </table>
          </ScrollArea>
        </div>
        <table className="score-table-points">
          <thead />
          <tbody style={{ width: '100%' }}>
            <tr>
              <th className="score-table-points-col-id">
                <div>{t('points')}</div>
              </th>
              <th className="score-table-points-col">
                <>
                  {myPos === 'player1' && (
                    <>{(totalPnts && totalPnts.player4 ? totalPnts.player4 : 0)}</>
                  )}
                  {myPos === 'player2' && (
                    <>{(totalPnts && totalPnts.player1 ? totalPnts.player1 : 0)}</>
                  )}
                  {myPos === 'player3' && (
                    <>{(totalPnts && totalPnts.player2 ? totalPnts.player2 : 0)}</>
                  )}
                  {myPos === 'player4' && (
                    <>{(totalPnts && totalPnts.player3 ? totalPnts.player3 : 0)}</>
                  )}
                </>
              </th>
              <th className="score-table-points-col">
                <>
                  {myPos === 'player1' && (
                    <>{(totalPnts && totalPnts.player1 ? totalPnts.player1 : 0)}</>
                  )}
                  {myPos === 'player2' && (
                    <>{(totalPnts && totalPnts.player2 ? totalPnts.player2 : 0)}</>
                  )}
                  {myPos === 'player3' && (
                    <>{(totalPnts && totalPnts.player3 ? totalPnts.player3 : 0)}</>
                  )}
                  {myPos === 'player4' && (
                    <>{(totalPnts && totalPnts.player4 ? totalPnts.player4 : 0)}</>
                  )}
                </>
              </th>
              <th className="score-table-points-col">
                <>
                  {myPos === 'player1' && (
                    <>{(totalPnts && totalPnts.player2 ? totalPnts.player2 : 0)}</>
                  )}
                  {myPos === 'player2' && (
                    <>{(totalPnts && totalPnts.player3 ? totalPnts.player3 : 0)}</>
                  )}
                  {myPos === 'player3' && (
                    <>{(totalPnts && totalPnts.player4 ? totalPnts.player4 : 0)}</>
                  )}
                  {myPos === 'player4' && (
                    <>{(totalPnts && totalPnts.player1 ? totalPnts.player1 : 0)}</>
                  )}
                </>
              </th>
              <th className="score-table-points-col">
                <>
                  {myPos === 'player1' && (
                    <>{(totalPnts && totalPnts.player3 ? totalPnts.player3 : 0)}</>
                  )}
                  {myPos === 'player2' && (
                    <>{(totalPnts && totalPnts.player4 ? totalPnts.player4 : 0)}</>
                  )}
                  {myPos === 'player3' && (
                    <>{(totalPnts && totalPnts.player1 ? totalPnts.player1 : 0)}</>
                  )}
                  {myPos === 'player4' && (
                    <>{(totalPnts && totalPnts.player2 ? totalPnts.player2 : 0)}</>
                  )}
                </>
              </th>
              <th className="score-table-points-col-pules" />
            </tr>
          </tbody>
        </table>
        <table className="score-table-sum">
          <thead />
          <tbody style={{ width: '100%' }}>
            <tr>
              <th className="score-table-sum-col-id">
                <div>
                  <Media src={coinImg} className="player-balance-coin" />
                </div>
              </th>
              <th className="score-table-points-col width-20">
                <>
                  {myPos === 'player1' && (
                    <>
                      {totalPnts && totalPnts.player4 && betRatio
                        ? betRatio * totalPnts.player4
                        : 0}
                    </>
                  )}
                  {myPos === 'player2' && (
                    <>
                      {totalPnts && totalPnts.player1 && betRatio
                        ? betRatio * totalPnts.player1
                        : 0}
                    </>
                  )}
                  {myPos === 'player3' && (
                    <>
                      {totalPnts && totalPnts.player2 && betRatio
                        ? betRatio * totalPnts.player2
                        : 0}
                    </>
                  )}
                  {myPos === 'player4' && (
                    <>
                      {totalPnts && totalPnts.player3 && betRatio
                        ? betRatio * totalPnts.player3
                        : 0}
                    </>
                  )}
                </>
              </th>
              <th className="score-table-points-col width-20">
                <>
                  {myPos === 'player1' && (
                    <>
                      {totalPnts && totalPnts.player1 && betRatio
                        ? betRatio * totalPnts.player1
                        : 0}
                    </>
                  )}
                  {myPos === 'player2' && (
                    <>
                      {totalPnts && totalPnts.player2 && betRatio
                        ? betRatio * totalPnts.player2
                        : 0}
                    </>
                  )}
                  {myPos === 'player3' && (
                    <>
                      {totalPnts && totalPnts.player3 && betRatio
                        ? betRatio * totalPnts.player3
                        : 0}
                    </>
                  )}
                  {myPos === 'player4' && (
                    <>
                      {totalPnts && totalPnts.player4 && betRatio
                        ? betRatio * totalPnts.player4
                        : 0}
                    </>
                  )}
                </>
              </th>
              <th className="score-table-points-col width-20">
                <>
                  {myPos === 'player1' && (
                    <>
                      {totalPnts && totalPnts.player2 && betRatio
                        ? betRatio * totalPnts.player2
                        : 0}
                    </>
                  )}
                  {myPos === 'player2' && (
                    <>
                      {totalPnts && totalPnts.player3 && betRatio
                        ? betRatio * totalPnts.player3
                        : 0}
                    </>
                  )}
                  {myPos === 'player3' && (
                    <>
                      {totalPnts && totalPnts.player4 && betRatio
                        ? betRatio * totalPnts.player4
                        : 0}
                    </>
                  )}
                  {myPos === 'player4' && (
                    <>
                      {totalPnts && totalPnts.player1 && betRatio
                        ? betRatio * totalPnts.player1
                        : 0}
                    </>
                  )}
                </>
              </th>
              <th className="score-table-points-col width-20">
                <>
                  {myPos === 'player1' && (
                    <>
                      {totalPnts && totalPnts.player3 && betRatio
                        ? betRatio * totalPnts.player3
                        : 0}
                    </>
                  )}
                  {myPos === 'player2' && (
                    <>
                      {totalPnts && totalPnts.player4 && betRatio
                        ? betRatio * totalPnts.player4
                        : 0}
                    </>
                  )}
                  {myPos === 'player3' && (
                    <>
                      {totalPnts && totalPnts.player1 && betRatio
                        ? betRatio * totalPnts.player1
                        : 0}
                    </>
                  )}
                  {myPos === 'player4' && (
                    <>
                      {totalPnts && totalPnts.player2 && betRatio
                        ? betRatio * totalPnts.player2
                        : 0}
                    </>
                  )}
                </>
              </th>
              <th className="score-table-sum-col-pules" />
            </tr>
          </tbody>
        </table>
        {isEndResultTable ? (
          <>
            {gameType && gameType.includes('P') ? (
              <EndResultPules t={t} betRatio={betRatio} />
            ) : null}
            {closeReason && closeReason.reason && (closeReason.reason === 'missedTurn' || closeReason.reason === 'leftRoom') ? (
              <EndResultFines showInfoModal={showInfoModal} />
            ) : null}
          </>
        ) : (null)}
      </div>
    );
  }
}

//export default withTranslation('game')(ScoreTable);
const mapStateToProps = (state, ownProps) => {
  if (state.game) {
    return ({
      // myPos: state.game.myPos || null,
      globalParams: state.game.globalParams || {},
      players: state.game.players,
    });
  } else {
    return null;
  }
};

// export default connect(mapStateToProps, null)(EndResultFines);
export default connect(mapStateToProps, null)(withTranslation('game')(ScoreTable));
